<template>
  <div>
    <huboo-loading-screen v-if="loading" :loading="loading" ref="loading-screen" />

    <huboo-page v-if="dataReady">
      <huboo-page-heading :heading="pageHeading">
        <template v-slot:subheading>
          <span>
            <HubButton name="edit-modal-button" @click="handleModalClick">
              {{ $t('clientSummary.editButton') }}
            </HubButton>
          </span>
        </template>
      </huboo-page-heading>

      <ClientEditModal
        v-model="editModal"
        :client="getSelectedBox"
        @close="onEditClose"
        @updated-item="fetchBox"
      />

      <hub-card name="client-summary-card">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2>{{ $t('clientSummary.clientDetailsSection') }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6" sm="12" xs="12">
              <div class="client-content">
                <h3 class="client-content__subtitle">
                  {{ $t('clientSummary.clientDetailsSubSection') }}
                </h3>
                <table>
                  <tr v-for="(detail, index) in clientDetails" :key="index">
                    <th class="table-headers">
                      <b>{{ detail[0] }}</b>
                    </th>
                    <td>{{ detail[1] }}</td>
                  </tr>
                </table>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="12" xs="12">
              <div class="client-content">
                <h3 class="client-content__subtitle">
                  {{ $t('clientSummary.billingDetailsSubSection') }}
                </h3>
                <table>
                  <tr v-for="(detail, index) in billingDetails" :key="index">
                    <th class="table-headers">
                      <b>{{ detail[0] }}</b>
                    </th>
                    <td>{{ detail[1] }}</td>
                  </tr>
                </table>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="12" xs="12">
              <div class="client-content">
                <h3 class="client-content__subtitle">
                  {{ $t('clientSummary.pickingDetailsSubSection') }}
                </h3>
                <table>
                  <tr v-for="(detail, index) in pickingAndStorageDetails" :key="index">
                    <th class="table-headers">
                      <b>{{ detail[0] }}</b>
                    </th>
                    <td>{{ detail[1] }}</td>
                  </tr>
                </table>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="12" xs="12">
              <div class="client-content">
                <h3 class="client-content__subtitle">
                  {{ $t('clientSummary.priceListVersion') }}
                </h3>
                <table>
                  <tr v-for="(detail, index) in priceListDetails" :key="index">
                    <th class="table-headers">
                      <b>{{ detail[0] }}</b>
                    </th>
                    <td>{{ detail[1] }}</td>
                  </tr>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </hub-card>
    </huboo-page>
  </div>
</template>

<script>
import { title } from '@/utilities/filters'
import { BILLING_TYPES, PICK_RATE_TYPES, STORAGE_TYPES } from '@/utilities/constants'
import { HubButton } from '@hubootech/ui-library'
import ClientEditModal from './ClientEditModal.vue'
import { mapGetters } from 'vuex'
import { formatDate } from '@/views/pages/billing/billingViewHelpers.js'
import VersionedPriceListMixin from '@/mixins/versionedPriceList'

const { MULTI_ITEM, PICK_RATE } = BILLING_TYPES
const { CONTAINER, PICK_NUMBER } = PICK_RATE_TYPES
const { ITEM_STORAGE, LEGACY_STORAGE, REPLEN_STORAGE } = STORAGE_TYPES

export default {
  name: 'ClientSummary',
  mixins: [VersionedPriceListMixin],
  components: {
    HubButton,
    ClientEditModal,
  },
  data: () => ({
    editModal: false,
  }),
  computed: {
    ...mapGetters('billing', ['getOriginRegions', 'getSelectedBox', 'getPriceListVersions']),

    pageHeading() {
      return this.$t('clientSummary.pageTitle') + ': ' + this.getSelectedBox?.hubooBox || title
    },
    clientDetails() {
      return [
        [this.$t('clientSummary.invoiceName'), this.getSelectedBox?.invoiceName],
        [this.$t('clientSummary.invoiceEmail'), this.getSelectedBox?.invoiceEmail],
        [this.$t('clientSummary.invoicingPreference'), this.getSelectedBox?.invoicingPreference],
        [
          this.$t('clientSummary.invoiceTerms'),
          this.getSelectedBox?.invoiceTerms ?? this.$t('common.none'),
        ],
        [this.$t('clientSummary.vatExempt'), this.getSelectedBox?.vatExempt],
        [
          this.$t('clientSummary.accountProtection'),
          this.getSelectedBox?.accountProtection ?? this.$t('common.none'),
        ],
        [
          this.$t('clientSummary.externalGuid'),
          this.getSelectedBox?.externalGuid ?? this.$t('common.none'),
        ],
        [title(this.$t('common.enabled')), this.getSelectedBox?.enabled],
      ]
    },
    billingDetails() {
      return [
        [
          this.$t('clientSummary.billingType'),
          this.getBillingTypeFullName(this.getSelectedBox?.billingType),
        ],
        [this.$t('clientSummary.currency'), this.getSelectedBox?.currency ?? '-'],
        [
          this.$t('clientSummary.billingRegion'),
          this.getBillingRegionName(this.getSelectedBox?.billingRegion),
        ],
        [
          this.$t('clientSummary.operatingRegions'),
          this.getOperatingRegionName(this.getSelectedBox?.operatingRegions),
        ],
      ]
    },
    pickingAndStorageDetails() {
      return [
        [
          this.$t('clientSummary.pickRateType'),
          this.getPickRateTypeFullName(this.getSelectedBox?.pickRateTypeId),
        ],
        [
          this.$t('clientSummary.perItemLimit'),
          this.getSelectedBox?.perItemLimit ?? this.$t('common.none'),
        ],
        [this.$t('clientSummary.firstPickFree'), this.getSelectedBox?.firstPickFree],
        [this.$t('clientSummary.multiItemDiscount'), this.getSelectedBox?.multiItemDiscount ?? '0'],
        [
          this.$t('clientSummary.storageType'),
          this.getStorageTypeFullName(this.getSelectedBox?.storageType),
        ],
        [this.$t('clientSummary.storageDiscount'), this.getSelectedBox?.storageDiscount ?? '0'],
        [
          this.$t('clientSummary.allowXMonthsFreeStorage'),
          this.getSelectedBox?.allowXMonthsFreeStorage ?? '0',
        ],
      ]
    },
    priceListDetails() {
      return [
        [this.$t('clientSummary.priceListVersionName'), this.clientPriceListVersion?.name ?? '-'],
        [
          this.$t('clientSummary.priceListVersionCreatedAt'),
          formatDate(this, this.clientPriceListVersion?.createdAt, 'dd/MM/yyyy'),
        ],
      ]
    },
    availableRegions() {
      return (
        this.getOriginRegions?.map(r => ({
          text: r.name,
          value: r.code,
        })) || []
      )
    },

    clientPriceListVersion() {
      return this.getPriceListVersions?.data.find(
        version => version.id === this.getSelectedBox?.priceListVersionId
      )
    },

    boxId() {
      return this.getSelectedBox?.hubooBox
    },

    dataReady() {
      return this.getPriceListVersions.data.length && this.getSelectedBox
    },

    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: 'price-list-version',
      })
    },
  },
  methods: {
    fetchBox() {
      this.$store.dispatch('billing/fetchClientBillingSetupByHubooBox', this.boxId)
    },
    getBillingTypeFullName(type) {
      switch (type) {
        case MULTI_ITEM:
          return this.$t('billing.multiItem')
        case PICK_RATE:
          return this.$t('billing.pickRate')
        default:
          return this.$t('common.unknown')
      }
    },
    getPickRateTypeFullName(type) {
      switch (type) {
        case CONTAINER:
          return this.$t('billing.container')
        case PICK_NUMBER:
          return this.$t('billing.pickNumber')
        default:
          return this.$t('common.unknown')
      }
    },
    getStorageTypeFullName(type) {
      switch (type) {
        case ITEM_STORAGE:
          return this.$t('billing.itemStorage')
        case LEGACY_STORAGE:
          return this.$t('billing.legacyStorage')
        case REPLEN_STORAGE:
          return this.$t('billing.replenStorage')
        default:
          return this.$t('common.unknown')
      }
    },

    getBillingRegionName(br) {
      if (br === null) return this.$t('clientSummary.noBillingRegion')
      const currentRegion = this.availableRegions.find(r => r.value === br)
      return currentRegion.text
    },

    getOperatingRegionName(regions) {
      if (regions.length === 0) return this.$t('clientSummary.noOperatingRegion')
      const regionNames = this.availableRegions
        .filter(region => regions.includes(region.value))
        .map(region => region.text)
      return regionNames.join(', ')
    },

    handleModalClick() {
      this.editModal = true
      this.$store.dispatch('billing/fetchPriceListVersions', { force: true })
    },

    onEditClose() {
      this.editModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.client-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 2rem;
  min-width: 20%;
}

.client-content__subtitle {
  margin-bottom: 0.625rem;
}

th,
td {
  text-align: left;
}

.table-headers {
  min-width: 12.5rem;
}

td {
  padding-left: 2rem;
}
</style>
