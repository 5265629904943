import { render, staticRenderFns } from "./ClientSummary.vue?vue&type=template&id=30cc9a43&scoped=true&"
import script from "./ClientSummary.vue?vue&type=script&lang=js&"
export * from "./ClientSummary.vue?vue&type=script&lang=js&"
import style0 from "./ClientSummary.vue?vue&type=style&index=0&id=30cc9a43&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30cc9a43",
  null
  
)

export default component.exports